import type { TFormControl, TMathControl, TSelectControl } from "@viuch/form-builder/types/controls";

export const valueMathControl: TMathControl = {
    path: "./value",
    control: "Math",
    label: "Значение",
};

export const answerMathControl: TMathControl = {
    path: "./answer",
    control: "Math",
    label: "Ответ",
};

export const answerTargetFormatMathControl: TSelectControl = {
    path: "./target",
    control: "Select",
    label: "Формат ответа",
    initialValue: null,
    data: {
        type: "static-items",
        items: [
            {
                value: null,
                label: "По умолчанию",
            },
            {
                value: "decimal",
                label: "Десятичная дробь",
            },
            {
                value: "mixed_fraction",
                label: "Смешанная дродь",
            },
            {
                value: "fraction",
                label: "Обыкновенная дробь",
            },
        ],
    },
};

export const variableMathControl: TMathControl = {
    path: "./variable",
    control: "Math",
    label: "Переменная",
};

export const equationMathControl: TMathControl = {
    path: "./equation",
    control: "Math",
    label: "Уравнение",
};

export const exprMathControl: TMathControl = {
    path: "./expr",
    control: "Math",
    label: "Выражение",
};

export const leftRightMathControl: TFormControl = {
    control: "Form",
    layout: "row",
    controls: [
        {
            path: "./left",
            control: "Math",
            label: "Слева",
        },
        {
            path: "./right",
            control: "Math",
            label: "Справа",
        },
    ],
};

export const intervalMathControl: TMathControl = {
    path: "./interval",
    control: "Math",
    label: "Интервал",
};
